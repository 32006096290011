.o-spinner {
    width: 2rem;
    height: 2rem;
    color: $main-background;
}

.clock {
    font-size: 100%;
    width: 3em;
    height: 3em;
    background: transparent;
    border: 5px solid #666;
    border-radius: 50%;
    position: relative;
    box-sizing: content-box;
}

.clock:after,
.clock:before {
    position: absolute;
    content: '';
    background: #999;
    border-radius: 50%;
}

.clock:after {
    width: 1.3em;
    height: .15em;
    top: 1.45em;
    left: 1.45em;
    transform-origin: .1em .1em;
    animation: minutehand .75s linear infinite;
}

.clock:before {
    width: 1em;
    height: .2em;
    top: 1.45em;
    left: 1.45em;
    transform-origin: .1em .1em;
    animation: hourhand 6s linear infinite;
}

@keyframes minutehand {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes hourhand {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}