.o-sidebar {
    margin-top: 5rem;
}

.o-sidebar-label {
    background-color: $main-background;
    color: white;
    width: 100%;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.o-close-sidebar {
    //background-color: #e6e6e6;
}