.multiselectCustom {
  height: 1.4rem;
  padding: 0;
  border: 0;
  border-radius: 0.25rem;
  background: none;
}
.o-tooltip {
  padding: 10px;
}
.tooltip-inner {
  max-width: 300px;
  width: 300px;
}

@media print {
  .tooltip {
    display: none !important;
  }
}

/* Overwrite calendar component style */
.gantt-schedule-timeline-calendar__chart-calendar {
  background-color: $main-background !important;
}
.gantt-schedule-timeline-calendar__chart-calendar-date-content--day,
.gantt-schedule-timeline-calendar__chart-calendar-date--month,
.gantt-schedule-timeline-calendar__chart-calendar-date-content.gstc-date-thin,
.gantt-schedule-timeline-calendar__chart-calendar-date-content.gstc-date-bold,
.gantt-schedule-timeline-calendar__list-column-header,
.gantt-schedule-timeline-calendar__chart-calendar-date-content--hour {
  background-color: $main-background !important;
  color: white !important;
}
.gantt-schedule-timeline-calendar__list-column-row-content {
  color: black;
  font-weight: 400;
  font-size: 1.1em;
}
.gantt-schedule-timeline-calendar__chart-calendar-date--level-1.gstc-current {
  box-shadow: none !important;
}
