// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito:wght@400;500;700");

// Variables
@import "../sass/_variables.scss";

$primary: $main-background !default;

// Bootstrap
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

//font-awesome
// @import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");

//components
@import "../sass/auth/login";
@import "../sass/layouts/header";
@import "../sass/layouts/footer";
@import "../sass/layouts/global";
@import "../sass/layouts/content";

@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Karla:wght@400;500;600;700&family=Lora:wght@500&family=Nunito+Sans:wght@200&family=Quicksand&family=Raleway:wght@300&family=Varela&display=swap");
@import "../sass/app.scss";
@import "../sass/buttons.scss";
@import "../sass/typo.scss";
@import "../sass/sidebar.scss";
@import "../sass/calendar.scss";
@import "../sass/input.scss";
@import "../sass/blocks.scss";
@import "../sass/spinners.scss";
@import "../sass/toasts.scss";
@import "../sass/table.scss";
@import "../sass/canvas.scss";

body {
    font-family: "Karla", sans-serif !important;
    font-size: 16px !important;
}
:root {
    --fc-button-bg-color: #094074;
    --fc-button-border-color: #062f55;
    --fc-button-hover-bg-color: #062f55;
    --fc-button-hover-border-color: #03213d;
    --fc-button-active-bg-color: #03213d;
    --fc-button-active-border-color: #021222;
}
