
//Style for the div and pills
.nav-pills {
    box-shadow: 0 3px 6px grey;
    background-color: $secondary-background;
    border-radius: 3px;
    padding: 1%;
    margin: 3% 3% 0 3%;
    z-index: 2;
    position: relative;

    &>.nav-item {
        width: auto;
        margin-left: 1%;
    }

    .nav-link.active {
        background-color: $third-background !important;
        color: black !important;
    }
}

.tab-content {
    box-shadow: 0 0 15px grey;
    border-radius: 3px;
    margin: -2% 2% 2% 2%;
    padding: 4% 2% 3% 2%;
}

.param-sublabel {
    box-shadow: 0 3px 6px grey;
    background-color: $secondary-background;
    border-radius: 3px;
    padding: 1%;
    margin: -3% 0.5% 3% 0.5%;
    z-index: 1;
    position: relative;
    text-align: center;
    color: white;
}
