footer {
    bottom: 0;
    background-color: $main-background;
    box-shadow: 0px -6px 12px 0px rgba(0, 0, 0, .2);
    z-index: 100;
}

.o-footer-bar {
    z-index: 99;
}

.footer-solians-bepro {
    text-align: center;
}

.nav-link {
    color: black;
}

.o-nav-links-footer {
    .nav-link {
        color: white !important;
    }
}

.logos {
    max-width: 30px;
    padding-bottom: 0.5%;
}

.fa-footer-green {
    color: green;
}

.fa-footer-yellow {
    color: #fff799;
}
