@media print {
    .gstc {
        background-color: red;
        width: 100%;
    }

    .gantt-schedule-timeline-calendar {
        background-color: red;
        width: 100%;
    }

    .ignore-print, footer {
        display: none;
    }
}
