.b-toast {
  border-radius: 0.25rem !important;
  border: none !important;
}

.o-toast-success {
  background-color: $validate !important;
  border: none !important;

  h4 {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.o-toast-danger {
  background-color: $delete !important;
  border: none !important;

  h4 {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.o-toast-danger-dark {
  background-color: $deletedark !important;
  border: none !important;

  h4 {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.o-toast-success-dark {
  background-color: $validatedark !important;
  border: none !important;

  h4 {
    color: white !important;
  }

  p {
    color: white !important;
  }
}
