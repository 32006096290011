.o-button-nav {
  font-family: "Cairo", sans-serif;
  border-radius: 0.25rem !important;
  color: white !important;

  &.active {
    background-color: $third-background;
    font-weight: normal;
  }

  &:hover {
    background-color: $secondary-background;
  }
}

.o-button-unselected {
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
  box-shadow: 0;
}

.o-button {
  font-family: "Cairo", sans-serif;
  border-radius: 0.25rem !important;
  -webkit-box-shadow: 2px 2px 5px 0px grey;
  -moz-box-shadow: 2px 2px 5px 0px grey;
  box-shadow: 2px 2px 5px 0px grey;
  min-width: 8rem;
  min-height: 2.6rem;

  &:focus {
    outline: none;
    -webkit-box-shadow: 2px 2px 5px 0px grey !important;
    -moz-box-shadow: 2px 2px 5px 0px grey !important;
    box-shadow: 2px 2px 5px 0px grey !important;
  }
}

.o-button-primary {
  background-color: $main-background !important;
  border-color: $main-background !important;
  color: white !important;
  min-width: 8rem;
  min-height: 2rem;

  &:hover {
    border-color: $main-background-dark !important;
    background-color: $main-background-dark !important;
  }
}

.o-button-primary-unselected {
  background-color: white !important;
  border-color: $main-background !important;
  color: $main-background !important;
  min-width: 8rem;
  min-height: 2rem;

  &:hover {
    border-color: $main-background-dark !important;
    background-color: $main-background-dark !important;
  }
}

.o-button-validate {
  background-color: $validate !important;
  border-color: $validate !important;
  color: white !important;

  &:hover {
    background-color: $validatedark !important;
  }
}

.o-button-update {
  background-color: $update !important;
  border-color: $update !important;
  min-width: 8rem;
  min-height: 2.6rem;
}

.o-button-delete {
  background-color: $delete !important;
  border-color: $delete !important;
  color: white !important;

  &:hover {
    border-color: $deletedark !important;
    background-color: $deletedark !important;
  }
}

.o-button-mobile {
  background-color: $third-background !important;
  border-color: $third-background !important;
  color: white !important;

  &:hover {
    border-color: $secondary-background !important;
    background-color: $secondary-background !important;
  }
}

.o-button-mini {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
  min-width: 0rem;
  min-height: 0rem;
}

.o-dropdown {
  color: white !important;

  button {
    color: white;
    border-radius: 0.25rem;
    outline: none;

    &:hover {
      color: white;
    }
  }

  .dropdown-menu {
    border-radius: 0.25rem;

    .dropdown-item {
      cursor: pointer;

      &:hover {
        background-color: $third-background;
      }
    }
  }
}

.o-button-sidebar {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.o-button-search-clear {
  cursor: pointer;
  font-weight: bold;
}

.o-button-toggle-inline-active {
  width: 100%;
  background-color: $main-background !important;
  border-color: $main-background !important;
  color: white !important;
  min-width: 8rem;
  min-height: 2rem;

  &:hover {
    border-color: $main-background-dark !important;
    background-color: $main-background-dark !important;
  }
}

.o-button-toggle-inline-inactive {
  width: 100%;
  background-color: white !important;
  border-color: $main-background !important;
  color: $main-background !important;
  min-width: 8rem;
  min-height: 2rem;

  &:hover {
    border-color: $main-background-dark !important;
    background-color: $main-background-dark !important;
  }
}

.o-back-to-top-button {
  background-color: $main-background;
  color: white;
  border-radius: 50px;
  border: none;
  padding: 1rem;
  -webkit-box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.4);
  &:focus {
    border: none;
    outline: none;
  }
}

.btn.btn-light {
  border-color: #ced4da;
}
