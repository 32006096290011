.o-select {
  border-radius: 0.25rem !important;
}

.o-form-group {
  label {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  input,
  select {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    opacity: 1;
    color: #2c3033;
    // background: none;
    // &:focus {
    //   box-shadow: none;
    //   border: none;
    //   color: black;
    // }
    // &:not([disabled]) {
    //   // border-bottom: 1px solid #ced4da;

    //   &.is-invalid {
    //     border-color: #ff0000;
    //   }
    // }
    // &:disabled {
    //   border: 1px solid transparent;
    //   background-color: transparent !important;
    // }
    &[type="date"] {
      min-width: 7.5rem;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.o-input-date {
  width: 100%;
  border: none;
  // border-bottom: 1px solid #ced4da;
  // background-color: transparent;
  // &:focus {
  //   border: none;
  //   border-bottom: 1px solid black;
  //   outline: none;
  // }
}

.o-input-date-mini {
  font-size: 9pt;
}

.o-input {
  height: 1.5rem;
  padding: 0.125rem 0.25rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  // border: none !important;
  // background-color: white !important;
  // // border-bottom: 1px solid #ced4da !important;
  // padding: 0 !important;
  // outline: none !important;
  // background-color: transparent !important;
  // border-radius: 0.25rem !important;

  // &:focus {
  //   outline: none !important;
  //   border: none !important;
  //   border-bottom: 1px solid black !important;
  //   box-shadow: none !important;
  //   border-radius: 0.25rem !important;
  //   background-color: transparent;
  // }
}

.o-input-text-area {
  padding: 0.25rem;
  &:focus {
    background-color: white !important;
  }
}

.a-input-designation {
  &:focus {
    background-color: transparent !important;
  }
}

.o-input-hour {
  //width: 5rem;
  input {
    max-width: 5rem !important;
  }
}

.o-input-minute {
  //width: 5rem;
  input {
    max-width: 5rem !important;
  }
}

.o-input-hour::after {
  content: "h";
}

.o-input-minute::after {
  content: "m";
}

.o-input-euros::after {
  content: "€";
}

.o-select {
  border-radius: 0.25rem;
}

.custom-control-label {
  &::before {
    border-radius: 0.25rem !important;
  }
}

.multiselect {
  &.is-invalid {
    .multiselect__tags {
      border-color: #ff0000;
    }
  }
}

.multiselect .multiselect__placeholder {
  font-size: 1rem;
}
.multiselect .multiselect__tags {
  border-radius: 0.25rem !important;
  border-color: #ced4da;
}
.multiselect.multiselect--active .multiselect__tags {
  border-color: #1281ea;
  box-shadow: 0 0 0 0.2rem rgb(9 64 116 / 25%);
}
.multiselect .multiselect__placeholder {
  margin: 0 0 0.25rem 0 !important;
  padding: 0 0 0 0.25rem !important;
  transform: translateY(-2px);
}
.multiselect .multiselect__single {
  transform: translateY(2px);
}
.multiselect.multiselect--active input {
  margin: 0 0 0.25rem 0 !important;
  padding: 0 0 0 0.25rem !important;
  transform: translateY(1px);
}
.multiselect .multiselect__input,
.multiselect .multiselect__single {
  background: transparent;
}
label {
  font-weight: 500;
  color: black;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.darker-inputs .form-control,
.darker-inputs .multiselect__tags,
.darker-inputs .custom-file-label,
.darker-inputs .custom-select {
  background-color: hsla(210deg, 9%, 31%, 0.06) !important;
  transition: 100ms background-color ease-in-out;
}
.darker-inputs .form-control:hover,
.darker-inputs .multiselect__tags:hover,
.darker-inputs .custom-file-label:hover,
.darker-inputs .custom-select:hover {
  background-color: hsla(210deg, 9%, 31%, 0.08) !important;
}

.input-error {
  font-size: 0.875rem;
  color: rgb(239 68 68);
}

// input[disabled="disabled"],
// select[disabled="disabled"] {
//   cursor: not-allowed;
//   filter: grayscale(1);
//   opacity: 0.6 !important;
// }
