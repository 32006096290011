.head-fenetre {
    background-color: $secondary-background;
    box-shadow: 0 3px 6px grey;
    border-radius: 3px;
    padding: 2%;
    margin: 3% 3% 0 3%;
    z-index: 1;
    position: relative;
}

.corps-fenetre {
    box-shadow: 0 0 15px grey;
    border-radius: 3px;
    margin: -2% 2% 2% 2%;
    padding: 6% 2% 4% 2%;
}