.o-total-block {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background-color: $main-background !important;
    color: white;

    .o-sub-block {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        background-color: white;
        color: black;

        .o-sub-block-title {
            color: white;
            cursor: pointer !important;

            .o-sub-block-title-toggle {
                margin-right: 0.5rem;
            }
        }

        .o-sub-block-title-bad {
            background-color: $delete;

            &:hover {
                background-color: $deletedark;
            }
        }

        .o-sub-block-title-good {
            background-color: $validate;

            &:hover {
                background-color: $validatedark;
            }
        }

        .o-sub-block-right-part {
            background-color: white;
            color: black;
        }
    }
}

.o-sort-block {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background-color: white !important;
    color: black;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.o-result-block {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background-color: #c3c3c3;

    .o-result-block-comment-toggle {
        font-size: 10pt;
    }

    .o-result-block-header {
        margin-top: 1rem;

        .o-result-block-header-status {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
            background-color: $warning;
            color: white;
            font-size: 16pt;
            margin-top: 0.5rem;
        }

        .o-result-block-header-status-good {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
            background-color: $validate;
            color: white;
            font-size: 16pt;
            margin-top: 0.5rem;
        }

        .o-result-block-header-status-total {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
            background-color: $validatedark;
            color: white;
            font-size: 16pt;
            margin-top: 0.75rem;
        }
    }

    .o-sub-block {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        background-color: white;
        color: black;

        .o-sub-block-title {
            color: white;
            cursor: pointer !important;

            .o-sub-block-title-toggle {
                margin-right: 0.5rem;
            }
        }

        .o-sub-block-title-bad {
            background-color: $delete;

            &:hover {
                background-color: $deletedark;
            }
        }

        .o-sub-block-title-good {
            background-color: $validate;

            &:hover {
                background-color: $validatedark;
            }
        }

        .o-sub-block-title-neutral {
            background-color: $main-background;
        }

        .o-sub-block-right-part {
            background-color: white;
            color: black;
        }
    }

    .o-block-sub-result {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        background-color: #ececec;

        .o-result-block-header-status {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
            background-color: $warning;
            color: white;
            font-size: 16pt;
            margin-top: 0.5rem;
        }
    }
}

.o-sub-block {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    color: black;

    .o-sub-block-title {
        color: white;
        cursor: pointer !important;

        .o-sub-block-title-toggle {
            margin-right: 0.5rem;
        }
    }

    .o-sub-block-title-bad {
        background-color: $delete;

        &:hover {
            background-color: $deletedark;
        }
    }

    .o-sub-block-title-good {
        background-color: $validate;

        &:hover {
            background-color: $validatedark;
        }
    }

    .o-sub-block-title-neutral {
        background-color: $main-background;
    }

    .o-sub-block-right-part {
        background-color: white;
        color: black;
    }
}

.o-block-create-sub-chantier {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background-color: #c3c3c3;
}

.o-bloc-selected {
    outline: 3px solid $main-background;
}

.o-bloc-editing {
    outline: 3px solid $warning;
}

.o-bloc-designation {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background-color: $main-background;
    color: white !important;
    font-size: 16pt;
    margin-top: 1rem;

    .form-control {
        color: white;

        &:focus {
            color: white;
        }
    }
}

.a-block-tdb {
    background-color: white;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    .a-sub-block-ecart-tdb {
        border-right: 2px solid $main-background;
    }
}
