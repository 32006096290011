h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Cairo', sans-serif;
}

.o-title {
    color: $secondary-background;
    font-size: 20pt;
    margin-bottom: 0.5rem;
}

.o-title-home {
    color: $secondary-background;
    font-size: 24pt;
    margin-bottom: 0.5rem;
}

.o-sub-title {
    color: $secondary-background;
}

.o-card-title {
    color: white;

    &:hover {
        text-decoration: none;
        cursor: default;
    }
}

.o-card-title:hover {
    text-decoration: none;
}

.o-brand {
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: 5px;
    font-size: 16pt;
    color: white !important;
}

.o-no-select {
    user-select: none;
}


.o-seach-icon {
    color: $main-background;
}

.o-home-para {
    @media (min-width: 992px) {
        min-height: 700px;
    }
    @media (min-width: 1162px) {
        min-height: 600px;
    }
    @media (min-width: 1300px) {
        min-height: 500px;
    }
    @media (min-width: 1800px) {
        min-height: 368px;
    }
}

.o-text-valid {
    color: $validate;
}

.o-text-invalid {
    color: $delete;
}